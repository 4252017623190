// Global CSS variables
:root {
  --height-menu-bar: 54px;

  --Black: #000000;
  --Blue2: #1d212b;
  --Blue3: #242936;
  --Blue3Bis: #2E3546;
  --Blue4: #3b4459;
  --Blue5: #566180;
  --Blue6: #12131c;
  --Bluewriting: #4283ff;
  --Blue: #2566e2;
  --Bluedarker: #1a4ba9;
  --Green: #00bfa5;
  --GreenDarker: #0d8274;
  --Grey1: #313232;
  --Grey2: #636465;
  --Grey3: #949597;
  --Grey4: #c6c7c9;
  --Grey5: #dee0e1;
  --Grey6: #eaecee;
  --Orange: #fa6400;
  --Red2: #ff1744;
  --Red: #ff3455;
  --RedDarker: #c3253f;
  --White: #ffffff;
  --Yellow2: #ffd625;
  --Yellow: #ffdd00;
  --YellowDarker: #edbd00;
}

#root,
body,
html {
  margin: 0;
  height: 100%;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 100%;
  background-color: var(--Blue2);
}

div,
button {
  outline: none;
}

.centered {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
}

::-webkit-scrollbar-thumb {
    background-color: #9e9e9e;
    outline: 0px;
 }
